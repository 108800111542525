import { GatsbyBrowser, navigate } from 'gatsby';

import './src/theme/tailwind.scss';
import Player from './src/components/atoms/player';
import wrapWithProvider from './wrap-with-provider';

export const wrapPageElement = ({ element, props }) => {
  return <Player {...props}>{element}</Player>;
};

export { onRouteUpdate } from '@simpel-web-mono/shared-util/utils';

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    const hash = window.location.hash;

    if (hash) {
      setTimeout(() => navigate(hash), 25); // just to be safe
    }
  };

export const wrapRootElement = wrapWithProvider;
