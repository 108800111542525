import { createRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { FaMusic } from '@react-icons/all-files/fa/FaMusic';
import { FaPause } from '@react-icons/all-files/fa/FaPause';
import cn from 'classnames';

import jingle from '../../sounds/jingle.mp3';
import { musicPlayerClicked } from '../../state/media-slice';

interface PlayerProps {
  children: any;
  musicPlayerPlaying: boolean;
}

const mapState = ({ musicPlayerPlaying }) => ({
  musicPlayerPlaying,
});

const Player = ({ children, musicPlayerPlaying }: PlayerProps): JSX.Element => {
  const dispatch = useDispatch();
  const audioRef = createRef<HTMLAudioElement>();

  useEffect(() => {
    if (musicPlayerPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [musicPlayerPlaying]);

  return (
    <>
      {children}
      <audio ref={audioRef} src={jingle} loop={true} />
      <button
        onClick={() => dispatch(musicPlayerClicked(!musicPlayerPlaying))}
        className={cn(
          `bg-bronze fixed bottom-8 right-8 z-30 flex h-14 w-14 items-center justify-center rounded-full border border-white text-white shadow-[6px_6px_20px_0px_rgba(0,0,0,0.8)]`,
          {
            'animate-bounce': !musicPlayerPlaying,
          },
        )}
        aria-label="Musik abspielen"
      >
        {musicPlayerPlaying ? <FaPause /> : <FaMusic />}
      </button>
    </>
  );
};

export default connect(mapState)(Player);
