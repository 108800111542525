import {
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface MediaState {
  youtubeApiLoaded: boolean;
  players: { player: YT.Player; id: number }[];
  musicPlayerPlaying: boolean;
}

export const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    youtubeApiLoaded: false,
    players: [],
    musicPlayerPlaying: false,
  },
  reducers: {
    youtubeApiLoadedSuccessfully: (state) => {
      state.youtubeApiLoaded = true;
    },
    addPlayer: (
      state,
      action: PayloadAction<{ player: YT.Player; id: number }>,
    ) => {
      state.players.push(action.payload);
    },
    startVideo: (state, _action: PayloadAction<number>) => {
      state.musicPlayerPlaying = false;
    },
    musicPlayerClicked: (state, action: PayloadAction<boolean>) => {
      state.musicPlayerPlaying = action.payload;
    },
  },
});

export const {
  youtubeApiLoadedSuccessfully,
  addPlayer,
  startVideo,
  musicPlayerClicked,
} = mediaSlice.actions;

export const mediaListenerMiddleware = createListenerMiddleware();

mediaListenerMiddleware.startListening.withTypes<MediaState>()({
  actionCreator: startVideo,
  effect: async (action, listenerApi) => {
    const affectedPlayers = listenerApi
      .getState()
      .players.filter((player) => player.id !== action.payload);

    // stop all other players
    affectedPlayers.forEach(({ player }) => {
      player.pauseVideo();
    });
  },
});

mediaListenerMiddleware.startListening.withTypes<MediaState>()({
  actionCreator: musicPlayerClicked,
  effect: async (action, listenerApi) => {
    // stop videos when music player is active
    listenerApi.getState().players.forEach(({ player }) => player.pauseVideo());
  },
});
