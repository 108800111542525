import { configureStore } from '@reduxjs/toolkit';

import { mediaListenerMiddleware, mediaSlice } from './media-slice';

const createStore = () =>
  configureStore({
    reducer: mediaSlice.reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['media/addPlayer'],
          ignoredPaths: ['players'],
        },
      }).prepend(mediaListenerMiddleware.middleware),
  });
export default createStore;
